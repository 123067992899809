<script setup lang="ts">
const props = withDefaults(
	defineProps<{
		duration?: number;
		distance?: number;
		mode?: "horizontal" | "vertical";
		active?: boolean;
	}>(),
	{ duration: 0.3, distance: -10, active: true }
);

const duration = computed(() => `transform ${props.duration}s`);
const distance = computed(() =>
	props.mode === "horizontal" ? `translateX(${props.distance}px)` : `translateY(${props.distance}px)`
);
</script>

<template>
	<div :class="{ animation: props.active }">
		<slot />
	</div>
</template>

<style scoped lang="scss">
.animation {
	transition: v-bind(duration);

	&:hover {
		transform: v-bind(distance);
	}
}
</style>
